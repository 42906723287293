const numberDisplayMap = [
  "zero",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine"
];

export function formatNumberDisplay(number: number, titleCase: boolean = false) {
  /*
    K2United style guide states that we should spell out numbers zero through nine, 
    and use digits for numbers 10 and higher. 
    */
  if (number >= 0 && number < 10) {
    const numberDisplay = numberDisplayMap[number];
    return titleCase
      ? numberDisplay.charAt(0).toUpperCase() + numberDisplay.slice(1)
      : numberDisplay;
  }
  return number;
}
