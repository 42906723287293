import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";
import { BREAKPOINTS } from "@/constants/breakpoints";

export function useResponsive() {
  const { width } = useWindowSize();

  const isMobile = computed(() => width.value < BREAKPOINTS.MOBILE);

  const isTablet = computed(
    () => width.value >= BREAKPOINTS.MOBILE && width.value < BREAKPOINTS.TABLET
  );

  const isDesktop = computed(() => width.value >= BREAKPOINTS.TABLET);

  const isWidescreen = computed(() => width.value >= BREAKPOINTS.DESKTOP);

  return {
    width,
    isMobile,
    isTablet,
    isDesktop,
    isWidescreen
  };
}
